<template>
    <div class="grid grid-cols-6 gap-6">
        <div class="col-span-6 sm:col-span-3 flex mt-3 ml-5">
            <datepicker v-model="availability.stateTimeFrameFrom">
                <template #icon>
                    <icon-calendar/>
                </template>
            </datepicker>
            <strong style="margin: auto">-</strong>
        </div>
        <div class="col-span-6 flex sm:col-span-3 mt-3 ml-5">
            <datepicker v-model="availability.stateTimeFrameTo">
                <template #icon>
                    <icon-calendar/>
                </template>
            </datepicker>
        </div>
    </div>
</template>

<script>
import Datepicker from '@/components/ui/Datepicker.vue'
import IconCalendar from '@/components/icons/IconCalendar.vue'
import { Availability } from 'delivery-boosting-2021-model'

export default {
    components: {
        Datepicker, IconCalendar
    },
    props: {
        availability: {
            type: Availability
        }
    },
    created () {
        /* setInterval(() => {
            // console.log('st', this.availability.stateTimeFrameFrom)
            // eslint-disable-next-line no-self-assign
            this.availability.stateTimeFrameFrom = '' + this.availability.stateTimeFrameFrom
        }
        , 1000) */
    }
}
</script>
