var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{on:{"close":_vm.close},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t('dashboard.category_edit1'))),_c('span',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.category.title))]),_vm._v(_vm._s(_vm.$t('dashboard.category_edit2')))]},proxy:true},{key:"interactive",fn:function(){return [_c('button-b',{staticClass:"bg-primary",attrs:{"loading":_vm.loading},nativeOn:{"click":function($event){return _vm.addAndSave.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('dashboard.save')))])]},proxy:true},{key:"body",fn:function(){return [_c('tabs',[_c('tab',{attrs:{"title":_vm.$t('dashboard.settings')}},[_c('div',{staticClass:"grid grid-cols-6 gap-6 mt-10"},[_c('div',{staticClass:"col-span-6"},[_c('input-normal',{attrs:{"inputtype":"text","inputname":"name","inputid":"name","inputplaceholder":"Salate"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t('dashboard.title')))]},proxy:true}]),model:{value:(_vm.category.title),callback:function ($$v) {_vm.$set(_vm.category, "title", $$v)},expression:"category.title"}})],1),_c('div',{staticClass:"col-span-6"},[_c('label',{staticClass:"block text-sm font-bold mb-1",attrs:{"for":"name"}},[_vm._v(_vm._s(_vm.$t('dashboard.status')))]),_c('multiselect',{attrs:{"options":[ 'ONLINE', 'OFFLINE', 'TIMED' ],"placeholder":_vm.$t('dashboard.choose_status'),"deselectLabel":_vm.$t('dashboard.remove'),"selectedLabel":_vm.$t('dashboard.selected'),"selectLabel":_vm.$t('dashboard.select'),"multiple":false},on:{"input":function($event){_vm.availability = _vm.category.availability}},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [_c('strong',[_vm._v(_vm._s(_vm.$t(("dashboard.status_" + option))))])]}},{key:"option",fn:function(ref){
var option = ref.option;
return [_vm._v(_vm._s(_vm.$t(("dashboard.status_" + option))))]}}]),model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}},[_c('template',{slot:"noResult"},[_vm._v(_vm._s(_vm.$t('dashboard.no_results')))])],2),_c('transition',{attrs:{"name":"slide-fade"}},[_c('timed-category',{directives:[{name:"show",rawName:"v-show",value:(_vm.state == 'TIMED'),expression:"state == 'TIMED'"}],attrs:{"availability":_vm.category.availability}})],1)],1),_c('div',{staticClass:"col-span-6"},[_c('label',{staticClass:"block text-sm font-bold mb-1",attrs:{"for":"name"}},[_vm._v(_vm._s(_vm.$t('dashboard.availability')))]),_c('multiselect',{attrs:{"options":[ 'ALWAYS', 'NEVER', 'TIMED' ],"placeholder":_vm.$t('dashboard.choose_availability'),"deselectLabel":_vm.$t('dashboard.remove'),"selectedLabel":_vm.$t('dashboard.selected'),"selectLabel":_vm.$t('dashboard.select'),"multiple":false},on:{"input":function($event){_vm.availability = _vm.category.availability}},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [_c('strong',[_vm._v(_vm._s(_vm.$t(("dashboard.available_" + option))))])]}},{key:"option",fn:function(ref){
var option = ref.option;
return [_vm._v(_vm._s(_vm.$t(("dashboard.available_" + option))))]}}]),model:{value:(_vm.available),callback:function ($$v) {_vm.available=$$v},expression:"available"}},[_c('template',{slot:"noResult"},[_vm._v(_vm._s(_vm.$t('dashboard.no_results'))+"!")]),_c('template',{slot:"noResult"},[_vm._v(_vm._s(_vm.$t('dashboard.no_results')))])],2),_c('transition',{attrs:{"name":"slide-fade"}},[_c('category-time-window',{directives:[{name:"show",rawName:"v-show",value:(_vm.available == 'TIMED'),expression:"available == 'TIMED'"}],attrs:{"availability":_vm.category.availability}})],1)],1),(_vm.restaurant.locations.length > 1)?_c('div',{staticClass:"mt-3 col-span-6"},[_c('label',{staticClass:"block text-sm font-bold mb-1"},[_vm._v(_vm._s(_vm.$t('dashboard.locations_choose')))]),_c('multiselect',{attrs:{"options":_vm.restaurant.locations,"track-by":"uuid","label":"generalTitle","placeholder":'Standorte wählen',"deselectLabel":_vm.$t('dashboard.remove'),"selectedLabel":_vm.$t('dashboard.selected'),"selectLabel":_vm.$t('dashboard.select'),"multiple":true},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [_c('strong',[_vm._v(_vm._s(option.generalTitle))])]}},{key:"option",fn:function(ref){
var option = ref.option;
return [_vm._v(_vm._s(option.generalTitle))]}}],null,false,124507890),model:{value:(_vm.locations),callback:function ($$v) {_vm.locations=$$v},expression:"locations"}},[_c('template',{slot:"noResult"},[_vm._v(_vm._s(_vm.$t('dashboard.no_results')))])],2)],1):_vm._e(),_c('div',{staticClass:"col-span-6 cursor-pointer",on:{"click":function($event){_vm.drawerVisible = true}}},[_c('picture-placeholder',{staticClass:"mb-1",attrs:{"value":_vm.media},on:{"input":function (i) { return _vm.media = i; }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t("dashboard.bck_img")))]},proxy:true}])})],1)])]),_c('unsplash-search',{staticClass:"z-100 ",attrs:{"title":_vm.category.title,"value":_vm.media,"drawerVisible":_vm.drawerVisible,"modal":true,"fullResolution":false},on:{"hide":function($event){_vm.drawerVisible = false},"input":function (i) { return _vm.media = i; }}})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }