<template>
    <modal @close="close">
        <template #title>{{ $t('dashboard.extra_edit1') }}<span class="text-primary">{{ upgrade.title }}</span>{{ $t('dashboard.extra_edit2') }}</template>
        <template #interactive><button-b :loading="loading" class="bg-primary" @click.native="addAndSave">{{ $t('dashboard.save') }}</button-b></template>
        <template #body>
            <div class="grid grid-cols-6 gap-6">
                <div class="col-span-6">
                    <input-normal v-model="upgrade.title" inputtype="text" inputname="name" inputid="name" :inputplaceholder="$t('dashboard.example_extra')">
                        <template #title>{{ $t('dashboard.title') }}</template>
                    </input-normal>
                </div>
                <div class="col-span-6 sm:col-span-3">
                    <input-price v-model="deliveryPrice" inputtype="text" inputname="name" inputid="name" :inputplaceholder="$t('dashboard.money_placeholder')">
                    <template #title>{{ $t('dashboard.delivery_extr_price') }}</template>
                    </input-price>
                </div>
                <div class="col-span-6 sm:col-span-3">
                    <input-price v-model="pickupPrice" inputtype="text" inputname="name" inputid="name" inputplaceholder="4">
                        <template #title>{{ $t('dashboard.pick-up_extr_price') }}</template>
                    </input-price>
                </div>
                <div class="col-span-6 gap-6">
                    <checkbox v-model="upgrade.excludefromMinOrder">{{ $t('dashboard.exclude_price_upgrades')}}</checkbox>
                </div>
                <div class="col-span-6 gap-6">
                    <extras-availability :availability="upgrade.availability"/>
                </div>
                <!---
                <div class="col-span-6">
                    <label class="block text-sm font-bold" for="SelectGroup">{{ $t('dashboard.assign_group') }}</label>
                    <div class="flex mt-1">
                        <div class="w-4/5">
                            <multiselect
                            id="SelectGroup"
                            v-model="valueSelectGroup"
                            :tag-placeholder="$t('dashboard.add_additives')"
                            :placeholder="$t('dashboard.search_group')"
                            label="name"
                            track-by="id"
                            :options="optionsSelectGroup"
                            :multiple="true"
                            :taggable="true"
                            @tag="addTag"
                            :deselectLabel="$t('dashboard.remove')"
                            :selectedLabel="$t('dashboard.selected')"
                            :selectLabel="$t('dashboard.select')">
                            <template slot="noResult">{{ $t('dashboard.no_results') }}!</template>
                            </multiselect>
                        </div>
                        <div class="w-1/5 pl-4 flex items-center justify-end">
                            <button-l settings="line cursor-pointer">{{ $t('dashboard.add') }}</button-l>
                        </div>
                    </div>
                </div>
                <div class="col-span-6">
                    <label class="block text-sm font-bold" for="SelectType">{{ $t('dashboard.assign_type') }}</label>
                    <div class="flex mt-1">
                        <div class="w-4/5">
                            <multiselect
                            id="SelectType"
                            v-model="valueSelectType"
                            :tag-placeholder="$t('dashboard.add_additives')"
                            :placeholder="$t('dashboard.search_type')"
                            label="name"
                            track-by="id"
                            :options="optionsSelectType"
                            :multiple="true"
                            :taggable="true"
                            @tag="addTag"
                            :deselectLabel="$t('dashboard.remove')"
                            :selectedLabel="$t('dashboard.selected')"
                            :selectLabel="$t('dashboard.select')">
                            <template slot="noResult">{{ $t('dashboard.no_results') }}!</template>
                            </multiselect>
                        </div>
                        <div class="w-1/5 pl-4 flex items-center justify-end">
                            <button-l settings="line cursor-pointer">{{ $t('dashboard.add') }}</button-l>
                        </div>
                    </div>
                </div> -->
                <div class="col-span-6">
                    <div class="flex">
                        <checkbox v-model="upgrade.properties.vegetarian" checkboxname="Vegetarisch"><span class="text-base">{{ $t('dashboard.vegetarian') }}</span></checkbox>
                        <checkbox v-model="upgrade.properties.vegan" class="ml-10" checkboxname="Vegan"><span class="text-base">{{ $t('dashboard.vegan') }}</span></checkbox>
                        <checkbox v-model="upgrade.properties.glutenFree" class="ml-10" checkboxname="Glutenfrei"><span class="text-base">{{ $t('dashboard.glutenfree') }}</span></checkbox>
                        <checkbox v-model="upgrade.properties.basic" class="ml-10" checkboxname="Basisch"><span class="text-base">{{ $t('dashboard.basic') }}</span></checkbox>
                    </div>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import InputNormal from '../../components/ui/InputNormal.vue'
import ButtonB from '../../components/ui/ButtonB.vue'
import Modal from '../../components/dashboard/Modal.vue'
// import Multiselect from 'vue-multiselect'
// import ButtonL from '../ui/ButtonL.vue'
import InputPrice from '../ui/InputPrice.vue'
import Checkbox from '../ui/Checkbox.vue'
import { Upgrade } from 'delivery-boosting-2021-model'
import ExtrasAvailability from '@/components/dashboard/ExtrasAvailability.vue'

export default {
    name: 'DashboardSettings',
    data () {
        return {
            valueStatus: null,
            valueAvailable: null,
            valueSelectGroup: null,
            valueSelectType: null,
            loading: false
        }
    },
    methods: {
        close () {
            this.$emit('close')
        },
        addTag (newTag) {
            const tag = {
                name: newTag,
                code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
            }
            this.options.push(tag)
            this.value.push(tag)
        },
        async addAndSave () {
            this.loading = true
            await this.$store.state.catalog.commit()
            this.loading = false
            this.close()
        }
    },
    props: {
        upgrade: {
            type: Upgrade,
            default () {
                return new Upgrade()
            }
        }
    },
    components: {
        InputNormal,
        ButtonB,
        Modal,
        // Multiselect,
        // ButtonL,
        Checkbox,
        ExtrasAvailability,
        InputPrice
    },
    computed: {
        deliveryPrice: {
            get () {
                return (this.upgrade.getPriceByMethod('delivery') || { amount: 0 }).amount
            },
            set (val) {
                if (this.upgrade.price.find(p => p.method === 'delivery')) {
                    this.upgrade.getPriceByMethod('delivery').amount = val
                } else {
                    this.upgrade.addPrice({
                        method: 'delivery',
                        amount: val,
                        currency: 'EUR'
                    })
                }
            }
        },
        pickupPrice: {
            get () {
                return (this.upgrade.getPriceByMethod('pick-up') || { amount: 0 }).amount
            },
            set (val) {
                if (this.upgrade.price.find(p => p.method === 'pick-up')) {
                    this.upgrade.getPriceByMethod('pick-up').amount = val
                } else {
                    this.upgrade.addPrice({
                        method: 'pick-up',
                        amount: val,
                        currency: 'EUR'
                    })
                }
            }
        }

    }
}
</script>

<style src="@/assets/css/multiselect.css"></style>
