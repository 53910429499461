<template>
    <div class="flex justify-between border rounded p-5 items-center cursor-move">
        <span class="font-bold text-gray-600">{{ additive.title }}</span>
        <div class="flex">
            <a class="mr-2 hover:text-primary cursor-pointer" @click="$emit('remove')"><icon-trash class="h-7"/></a>
            <a class="hover:text-primary cursor-pointer" @click="openModal = true"><icon-cog class="h-7"/></a>
        </div>
        <transition name="slide-fade">
        <edit-additive :additive="additive" v-if="openModal" @close="openModal = false"></edit-additive>
        </transition>
    </div>
</template>

<script>
import IconCog from '../icons/IconCog.vue'
import IconTrash from '../icons/IconTrash.vue'
import EditAdditive from '../modals/EditAdditive'
import { Additive } from 'delivery-boosting-2021-model'
export default {
    components: { IconCog, IconTrash, EditAdditive },
    data () {
        return {
            openModal: false
        }
    },
    props: {
        additive: {
            type: Additive,
            default () {
                return new Additive()
            }
        }
    }
}
</script>

<style>
.slide-fade-enter-active {
  transition: all .5s ease;
}
.slide-fade-leave-active {
  transition: all .5s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(100px);
  opacity: 0;
}
</style>
