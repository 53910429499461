<template>
  <modal @close="close">
    <template #title>{{ $t("dashboard.new_Category") }}</template>
    <template #interactive
      ><button-b :loading="loading" class="bg-primary" @click.native="addAndSave">{{
        $t("dashboard.add")
      }}</button-b></template
    >
    <template #body>
      <tabs>
        <!-- Tab "Einstellungen" -->
        <tab :title="$t('dashboard.settings')">
          <div class="grid grid-cols-6 gap-6 mt-10">
            <div class="col-span-6">
              <input-normal
                v-model="category.title"
                inputtype="text"
                inputname="name"
                inputid="name1"
                inputplaceholder="Salate"
              >
                <template #title>{{ $t("dashboard.title") }}</template>
              </input-normal>
            </div>
            <!--
                        <div class="col-span-6">
                            <input-normal inputtype="text" inputname="name" inputid="name" inputplaceholder="4">
                                <template #title>{{ $t('dashboard.placement') }}</template>
                            </input-normal>
                        </div>-->
            <div class="col-span-6">
              <label for="name" class="block text-sm font-bold mb-1">{{
                $t("dashboard.status")
              }}</label>
              <multiselect
                v-model="state"
                @input="availability = category.availability"
                :options="['ONLINE', 'OFFLINE', 'TIMED']"
                :placeholder="$t('dashboard.choose_status')"
                :deselectLabel="$t('dashboard.remove')"
                :selectedLabel="$t('dashboard.selected')"
                :selectLabel="$t('dashboard.select')"
                :multiple="false"
                :preselectFirst="true"
              >
                <template slot="noResult">{{
                  $t("dashboard.no_results")
                }}</template>
                <template slot="singleLabel" slot-scope="{ option }"
                  ><strong>{{
                    $t(`dashboard.status_${option}`)
                  }}</strong></template
                >
                <template slot="option" slot-scope="{ option }">{{
                  $t(`dashboard.status_${option}`)
                }}</template>
              </multiselect>
              <transition name="slide-fade">
                <timed-category
                  v-show="state == 'TIMED'"
                  :availability="category.availability"
                />
              </transition>
            </div>
            <div class="col-span-6">
              <label for="name" class="block text-sm font-bold mb-1">{{
                $t('dashboard.availability')
              }}</label>
              <multiselect
                v-model="available"
                @input="availability = category.availability"
                :options="['ALWAYS', 'NEVER', 'TIMED']"
                :placeholder="$t('dashboard.choose_availability')"
                :deselectLabel="$t('dashboard.remove')"
                :selectedLabel="$t('dashboard.selected')"
                :selectLabel="$t('dashboard.select')"
                :multiple="false"
                :preselectFirst="true"
              >
                <template slot="noResult">{{
                  $t('dashboard.no_results')
                }}</template>
                <template slot="singleLabel" slot-scope="{ option }"
                  ><strong>{{
                    $t(`dashboard.available_${option}`)
                  }}</strong></template
                >
                <template slot="option" slot-scope="{ option }">{{
                  $t(`dashboard.available_${option}`)
                }}</template>
              </multiselect>
              <transition name="slide-fade">
                <category-time-window
                  v-show="available == 'TIMED'"
                  :availability="category.availability"
                />
              </transition>
            </div>
            <div v-if="restaurant.locations.length > 1" class="mt-3 col-span-6">
              <label class="block text-sm font-bold mb-1">{{
                $t('dashboard.locations_choose')
              }}</label>
              <multiselect
                v-model="locations"
                :options="locationOptions"
                track-by="uuid"
                label="generalTitle"
                :placeholder="'Standorte wählen'"
                :deselectLabel="$t('dashboard.remove')"
                :selectedLabel="$t('dashboard.selected')"
                :selectLabel="$t('dashboard.select')"
                :multiple="true"
              >
                <template slot="noResult">{{
                  $t('dashboard.no_results')
                }}</template>
                <template slot="singleLabel" slot-scope="{ option }"
                  ><strong>{{ option.generalTitle }}</strong></template
                >
                <template slot="option" slot-scope="{ option }">{{
                  option.generalTitle
                }}</template>
              </multiselect>
            </div>
            <div class="col-span-6 cursor-pointer" @click="drawerVisible = true">
              <picture-placeholder
                  class="mb-1"
                  :value="media"
                  @input="i => media = i"
                >
                  <template #title>{{ $t('dashboard.bck_img') }}</template>
                </picture-placeholder>
            </div>
          </div> </tab>
        <unsplash-search class="z-100 " :title ="category.title" :value="media" :drawerVisible="drawerVisible" :modal="true" :fullResolution="false" @hide="drawerVisible = false" @input="i => media = i"/>
        <!-- END: Tab "Einstellungen" -->
        <!-- Tab "Zusatzstoffe" -->
        <!-- TODO:
                <tab :title="$t('dashboard.preset')">
                    <h4 class="mt-10">{{ $t('dashboard.additives') }}</h4>
                    <div class="mt-5">
                        <multiselect
                        v-model="valueAdditive"
                        :tag-placeholder="$t('dashboard.add_additives')"
                        :placeholder="$t('dashboard.search_additives')"
                        label="name"
                        track-by="id"
                        :options="optionsAdditive"
                        :multiple="true"
                        :taggable="true"
                        @tag="addTag"
                        :deselectLabel="$t('dashboard.remove')"
                        :selectedLabel="$t('dashboard.selected')"
                        :selectLabel="$t('dashboard.select')">
                        <template slot="noResult">{{ $t('dashboard.no_results') }}</template>
                        </multiselect>
                    </div>
                    <h4 class="mt-10">{{ $t('dashboard.extras') }}</h4>
                    <div class="grid grid-cols-6 gap-6 mt-5">
                        <div class="col-span-6">
                            <label class="block text-sm font-bold mb-1" for="groupSelect">{{ $t('dashboard.group') }}</label>
                            <div class="flex">
                                <div class="w-3/4">
                                    <multiselect
                                    id="groupSelect"
                                    v-model="valueExtraGroup"
                                    :options="[ 'Pizza', 'Salate' ]"
                                    :placeholder="$t('dashboard.choose_group')"
                                    :deselectLabel="$t('dashboard.remove')"
                                    :selectedLabel="$t('dashboard.selected')"
                                    :selectLabel="$t('dashboard.select')">
                                    <template slot="noResult">{{ $t('dashboard.no_results') }}</template>
                                    </multiselect>
                                </div>
                                <div class="w-1/4 pl-4">
                                    <button-b settings="bg-primary w-full justify-center transparent">{{ $t('dashboard.load_groups') }}</button-b>
                                </div>
                            </div>
                            <hr class="mt-5">
                            <! -- List of all extra groups -- >
                            <div class="mt-5">
                                <label class="block text-sm font-bold mb-1" for="extraGroup1">{{ $t('dashboard.ingredients') }}</label>
                                <div class="flex mt-1">
                                    <div class="w-4/5">
                                        <multiselect
                                        id="extraGroup1"
                                        v-model="valueExtraGroup1"
                                        tag-placeholder="Zusatzstoffe hinzufügen"
                                        :placeholder="$t('dashboard.search_ingredient')"
                                        label="name"
                                        track-by="id"
                                        :options="optionsExtraGroup1"
                                        :multiple="true"
                                        :taggable="true"
                                        @tag="addTag"
                                        :deselectLabel="$t('dashboard.remove')"
                                        :selectedLabel="$t('dashboard.selected')"
                                        :selectLabel="$t('dashboard.select')">
                                        <template slot="noResult">{{$t('dashboard.no_results')}}</template>
                                        </multiselect>
                                    </div>
                                    <div class="w-1/5 pl-4 flex items-center justify-end">
                                        <button-l settings="line cursor-pointer">{{$t('dashboard.add')}}</button-l>
                                    </div>
                                </div>
                            </div>
                            <! -- END: List of all extra groups -- >
                        </div>
                    </div>
                </tab><! -- END: Tab "Zusatzstoffe" -->
      </tabs>
    </template>
  </modal>
</template>

<script>
import InputNormal from '@/components/ui/InputNormal.vue'
import ButtonB from '@/components/ui/ButtonB.vue'
import Modal from '@/components/dashboard/Modal.vue'
import Tabs from '@/components/ui/Tabs.vue'
import Tab from '@/components/ui/Tab.vue'
import Multiselect from 'vue-multiselect'
// import UploadDragAndDrop from '@/components/ui/UploadDragAndDrop.vue'
import ButtonL from '@/components/ui/ButtonL.vue'
import TimedCategory from '@/components/dashboard/TimedCategory.vue'
import CategoryTimeWindow from '@/components/dashboard/CategoryTimeWindow.vue'
import { Category } from 'delivery-boosting-2021-model'
import PicturePlaceholder from '../../components/ui/PicturePlaceholder.vue'
import UnsplashSearch from '../../components/modals/UnsplashSearch.vue'

export default {
    name: 'DashboardSettings',
    data () {
        return {
            drawerVisible: false,
            valueAvailable: null,
            valueStatus: null,
            valueAdditive: null,
            valueExtraGroup: null,
            valueExtraGroup1: null,
            optionsAdditive: [
                { name: 'Erdnüsse', id: '1' },
                { name: 'Milch', id: '2' },
                { name: 'Senf', id: '3' },
                { name: 'Krebstiere', id: '4' },
                { name: 'Schwefeldioxid und Sulphit', id: '5' },
                { name: 'Sesam', id: '6' }
            ],
            optionsExtraGroup1: [
                { name: 'Erdnüsse', id: '1' },
                { name: 'Milch', id: '2' },
                { name: 'Senf', id: '3' },
                { name: 'Krebstiere', id: '4' },
                { name: 'Schwefeldioxid und Sulphit', id: '5' },
                { name: 'Sesam', id: '6' }
            ],
            category: new Category({
                availability: {
                    state: 'ONLINE',
                    stateTimeFrame: {
                        from: '',
                        to: ''
                    },
                    available: 'ALWAYS',
                    availabilityTimeRules: {
                        cycle: 'WEEKLY',
                        availableOn: [],
                        timeFrames: []
                    },
                    locations: []
                }
            }),
            searchModal: false,
            loading: false
        }
    },
    computed: {
        restaurant () {
            return this.$store.state.restaurant
        },
        catalog () {
            return this.$store.state.catalog
        },
        media: {
            set (val) {
                this.category.media = [val.toPlainObject()].filter(m => m.url)
            },
            get () {
                return this.category.media[0]
            }
        },
        state: {
            get () {
                // eslint-disable-next-line no-unused-expressions
                this.i
                return this.category.availability.state || ''
            },
            set (state) {
                this.category.availability.state = state
            }
        },
        available: {
            get () {
                // eslint-disable-next-line no-unused-expressions
                this.i
                return this.category.availability.available || ''
            },
            set (available) {
                this.category.availability.available = available
            }
        },
        locations: {
            get () {
                // eslint-disable-next-line no-unused-expressions
                this.i
                return this.category.availability.locations.map(location => this.restaurant.locations.find(l => l.uuid === location) || {})
            },
            set (val) {
                // eslint-disable-next-line no-unused-expressions
                this.i
                this.category.availability.locations = val.map(location => location.uuid)
            }
        },
        locationOptions () {
            return this.restaurant ? this.restaurant.locations : []
        }
    },
    methods: {
        async addAndSave () {
            this.loading = true
            this.$store.state.catalog.addCategory(JSON.parse(JSON.stringify(this.category.toPlainObject())))
            await this.$store.state.catalog.commit()
            this.loading = false
            this.close()
        },
        close () {
            this.$emit('close')
        },
        addTag (newTag) {
            const tag = {
                name: newTag,
                code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
            }
            this.options.push(tag)
            this.value.push(tag)
        },
        selectLocation (option) {
            this.category.availability.addLocation(option.uuid)
        },
        removeLocation (option) {
            this.category.availability.removeLocation(option.uuid)
        }
    },
    created () {
        setInterval(() => { this.i = !this.i }, 100)
        if (this.restaurant.locations.length === 1) {
            this.category.availability.locations.push(this.restaurant.locations[0].uuid)
        }
    },
    components: {
        InputNormal,
        ButtonB,
        Modal,
        Tabs,
        Tab,
        Multiselect,
        UnsplashSearch,
        // eslint-disable-next-line vue/no-unused-components
        ButtonL,
        TimedCategory,
        CategoryTimeWindow,
        PicturePlaceholder
    }
}
</script>

<style src="@/assets/css/multiselect.css"></style>
