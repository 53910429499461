<template>
    <modal @close="abortAndClose">
        <template #title>{{ $t('dashboard.edit_additiv1') }}<span class="text-primary">{{ additive.title }}</span>{{ $t('dashboard.edit_additiv2') }}</template>
        <template #interactive><button-b :loading="loading" @click.native="saveAndClose" class="bg-primary">{{ $t('dashboard.save') }}</button-b></template>
        <template #body>
            <div class="grid grid-cols-6 gap-6">
                <!--
                <div class="col-span-6">
                    <label for="name" class="block text-sm font-bold mb-1">{{ $t('dashboard.status') }}</label>
                    <multiselect
                    v-model="valueStatus"
                    :options="[ 'Aktiv', 'Inaktiv' ]"
                    :placeholder="$t('dashboard.choose_status')"
                    :deselectLabel="$t('dashboard.remove')"
                    :selectedLabel="$t('dashboard.selected')"
                    :selectLabel="$t('dashboard.select')">
                    <template slot="noResult">{{ $t('dashboard.no_results') }}</template>
                    </multiselect>
                </div>
                -->
                <div class="col-span-6">
                    <input-normal v-model="additive.title" inputtype="text" inputname="name" inputid="name" :inputplaceholder="$t('dashboard.example')">
                        <template #title>{{ $t('dashboard.title') }}</template>
                    </input-normal>
                </div>
                <div class="col-span-6">
                    <input-textarea v-model="additive.description" :inputplaceholder="$t('dashboard.example_desc')">
                        <template #title>{{ $t('dashboard.description') }}</template>
                    </input-textarea>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import InputNormal from '../ui/InputNormal.vue'
import ButtonB from '../ui/ButtonB.vue'
import Modal from '../dashboard/Modal.vue'
// import Multiselect from 'vue-multiselect'
import InputTextarea from '../ui/InputTextarea.vue'
import { Additive } from 'delivery-boosting-2021-model'
export default {
    name: 'EditAdditive',
    data () {
        return {
            valueStatus: null,
            loading: false
        }
    },
    props: {
        additive: {
            type: Additive,
            default () {
                return new Additive()
            }
        }
    },
    methods: {
        async saveAndClose () {
            this.loading = true
            await this.$store.state.catalog.commit()
            this.loading = true
            this.close()
        },
        async abortAndClose () {
            await this.$store.dispatch('fetchRestaurant')
            this.close()
        },
        close () {
            this.$emit('close')
        }
    },
    components: {
        InputNormal,
        ButtonB,
        Modal,
        InputTextarea
    }
}
</script>

<style src="@/assets/css/multiselect.css"></style>
