<template>
    <div>
        <vue-good-table
        :columns="columns"
        :rows="rows"
        @change="(pl) => $emit('change', pl)"
        theme="deliveryboosting">
            <template slot="table-row" slot-scope="props">
                <span class="flex justify-end" v-if="props.column.field == 'after'">
                    <a @click="deleteModal = props.row.uuid" class="mr-4 hover:text-primary"><icon-trash class="h-6 cursor-pointer"/></a>
                    <a @click="editModal = props.row.uuid" class="hover:text-primary"><icon-cog class="h-6 cursor-pointer"/></a>
                    <transition name="slide-fade">
                    <delete-extra v-if="deleteModal === props.row.uuid" @close="deleteModal = null" :uuid="props.row.uuid" :title="props.row.title"/>
                    <edit-extra v-if="editModal === props.row.uuid" @close="editModal = null" :upgrade="$store.state.catalog.getUpgradeByUuid(props.row.uuid)"/>
                    </transition>
                </span>
                <span v-if="props.column.field == 'id'">
                    <span class="text-gray-600 bg-gray-500 bg-opacity-25 py-2 px-5 font-bold transparent rounded">
                    {{props.row.id}}
                    </span>
                </span>
                <span v-else-if="props.column.field == 'status'">
                    <badge :class="{'bg-purple': props.row.status == 'TIMED', 'bg-green': props.row.status == 'ONLINE', 'bg-red text-red': props.row.status == 'OFFLINE'}" settings="transparent">
                    {{ $t(`dashboard.status_${props.row.status}`) }}
                    </badge>
                </span>
                <span v-else-if="props.column.field == 'vegetarian'">
                    <span :class="{'hidden': props.row.vegetarian != true}" class="text-white font-bold bg-primary inline-flex rounded">
                        <icon-check class="h-6"/>
                    </span>
                </span>
                <span v-else-if="props.column.field == 'vegan'">
                    <span :class="{'hidden': props.row.vegan != true}" class="text-white font-bold bg-primary inline-flex rounded">
                        <icon-check class="h-6"/>
                    </span>
                </span>
                <span v-else-if="props.column.field == 'glutenFree'">
                    <span :class="{'hidden': props.row.glutenFree != true}" class="text-white font-bold bg-primary inline-flex rounded">
                        <icon-check class="h-6"/>
                    </span>
                </span>
                <span v-else-if="props.column.field == 'basic'">
                    <span :class="{'hidden': props.row.basic != true}" class="text-white font-bold bg-primary inline-flex rounded">
                        <icon-check class="h-6"/>
                    </span>
                </span>
                <span v-else-if="props.column.field == 'surcharge'">
                    <span class="text-primary font-bold">
                    {{ $n(props.row.surcharge/100, 'currency')}}
                    </span>
                </span>
                <span v-else-if="props.column.field == 'surcharge2'">
                    <span class="text-primary font-bold">
                    {{ $n(props.row.surcharge2/100, 'currency')}}
                    </span>
                </span>
                <span v-else-if="props.column.field == 'title'">
                    <span class="text-gray-600 font-bold">
                    {{props.row.title}}
                    </span>
                </span>
                <span v-else>
                {{props.formattedRow[props.column.field]}}
                </span>
            </template>
        </vue-good-table>
    </div>
</template>

<script>
// https://xaksis.github.io/vue-good-table/guide/style-configuration/#polar-bear
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import IconTrash from '../icons/IconTrash.vue'
import IconCog from '../icons/IconCog.vue'
import Badge from '../ui/Badge.vue'
import IconCheck from '../icons/IconCheck.vue'
import DeleteExtra from '@/components/modals/DeleteExtra.vue'
import EditExtra from '@/components/modals/EditExtra.vue'

export default {
    components: {
        VueGoodTable,
        IconTrash,
        IconCog,
        Badge,
        IconCheck,
        DeleteExtra,
        EditExtra
    },
    data () {
        return {
            columns: [
                { label: this.$t('dashboard.id'), field: 'id' }, { label: this.$t('dashboard.title'), field: 'title' }, { label: this.$t('dashboard.group'), field: 'group' }, { label: this.$t('dashboard.type'), field: 'type' }, { label: this.$t('dashboard.vegetarian'), field: 'vegetarian', thClass: 'text-center', tdClass: 'text-center' }, { label: this.$t('dashboard.vegan'), field: 'vegan', thClass: 'text-center', tdClass: 'text-center' }, { label: this.$t('dashboard.glutenfree'), field: 'glutenFree', thClass: 'text-center', tdClass: 'text-center' }, { label: this.$t('dashboard.basic'), field: 'basic', thClass: 'text-center', tdClass: 'text-center' }, { label: this.$t('dashboard.delivery_extr_price'), field: 'surcharge', thClass: 'text-center', tdClass: 'text-center' }, { label: this.$t('dashboard.pick-up_extr_price'), field: 'surcharge2', thClass: 'text-center', tdClass: 'text-center' }, { label: this.$t('dashboard.status'), field: 'status', thClass: 'text-center', tdClass: 'text-center' }, { label: '', field: 'after', sortable: false }
            ],
            deleteModal: null,
            editModal: null
        }
    },
    props: {
        upgrades: {
            type: Array,
            default () {
                return []
            }
        }
    },
    computed: {
        rows () {
            return this.upgrades.map(p => ({ upgrade: p, uuid: p.uuid, id: 1, title: p.title, group: '-', type: '-', vegetarian: p.properties.vegetarian, vegan: p.properties.vegan, glutenFree: p.properties.glutenFree, basic: p.properties.basic, surcharge: p.getPriceByMethod('delivery').amount, surcharge2: p.getPriceByMethod('pick-up').amount, status: p.availability.state, interaction: '' }))
        }
    }
}
</script>

<style>
    .slide-fade-enter-active {
    transition: all .5s ease;
    }
    .slide-fade-leave-active {
    transition: all .5s ease;
    }
    .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(100px);
    opacity: 0;
    }
    .vgt-inner-wrap {
        @apply shadow-none;
    }
    .deliveryboosting thead tr th {
        background: transparent !important;
        @apply text-gray-500;
    }
    .deliveryboosting, .deliveryboosting tr th, .deliveryboosting tr td {
        border: none !important;
    }
    .deliveryboosting thead tr, .deliveryboosting tbody tr {
        @apply border-b;
    }
    .deliveryboosting tbody tr td {
        @apply py-6 align-middle;
        @apply text-gray-500;
    }
</style>
