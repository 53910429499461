<template>
    <div class="mt-4 border-l border-b rounded-bl pb-5 pl-5">
        <dropdown v-model="availability.timeRulesCycle" settingslabel="mr-4" settings="flex items-center">
            <template #title>
                {{ $t('dashboard.regularity') }}
            </template>
            <template #options>
                <option value="WEEKLY">{{ $t('dashboard.weekly') }}</option>
            </template>
        </dropdown>
        <div class="grid gap-2 grid-flow-col grid-cols-7 mt-5">
            <button @click="toggleAvailableOn(1)" :class="{'bg-primary': availability.timeRulesAvailableOn.includes(1), 'bg-gray-500': !(availability.timeRulesAvailableOn.includes(1)) }" class="btn inline-flex items-center font-bold rounded-md focus:outline-none text-gray-500 sm transparent justify-center ">{{ $t('dashboard.short_monday') }}</button>
            <button @click="toggleAvailableOn(2)" :class="{'bg-primary': availability.timeRulesAvailableOn.includes(2), 'bg-gray-500': !(availability.timeRulesAvailableOn.includes(2)) }" class="btn inline-flex items-center font-bold rounded-md focus:outline-none text-gray-500 sm transparent justify-center ">{{ $t('dashboard.short_tuesday') }}</button>
            <button @click="toggleAvailableOn(3)" :class="{'bg-primary': availability.timeRulesAvailableOn.includes(3), 'bg-gray-500': !(availability.timeRulesAvailableOn.includes(3)) }" class="btn inline-flex items-center font-bold rounded-md focus:outline-none text-gray-500 sm transparent justify-center ">{{ $t('dashboard.short_wednesday') }}</button>
            <button @click="toggleAvailableOn(4)" :class="{'bg-primary': availability.timeRulesAvailableOn.includes(4), 'bg-gray-500': !(availability.timeRulesAvailableOn.includes(4)) }" class="btn inline-flex items-center font-bold rounded-md focus:outline-none text-gray-500 sm transparent justify-center ">{{ $t('dashboard.short_thursday') }}</button>
            <button @click="toggleAvailableOn(5)" :class="{'bg-primary': availability.timeRulesAvailableOn.includes(5), 'bg-gray-500': !(availability.timeRulesAvailableOn.includes(5)) }" class="btn inline-flex items-center font-bold rounded-md focus:outline-none text-gray-500 sm transparent justify-center ">{{ $t('dashboard.short_friday') }}</button>
            <button @click="toggleAvailableOn(6)" :class="{'bg-primary': availability.timeRulesAvailableOn.includes(6), 'bg-gray-500': !(availability.timeRulesAvailableOn.includes(6)) }" class="btn inline-flex items-center font-bold rounded-md focus:outline-none text-gray-500 sm transparent justify-center ">{{ $t('dashboard.short_saturday') }}</button>
            <button @click="toggleAvailableOn(0)" :class="{'bg-primary': availability.timeRulesAvailableOn.includes(0), 'bg-gray-500': !(availability.timeRulesAvailableOn.includes(0)) }" class="btn inline-flex items-center font-bold rounded-md focus:outline-none text-gray-500 sm transparent justify-center ">{{ $t('dashboard.short_sunday') }}</button>
        </div>
        <div class="items-center mt-5">
            <input-time v-for="(timeFrame, index) in availability.timeRulesTimeFrames" :key="index" :timeFrame="timeFrame" @remove="removeTimeFrame(index)"/>
            <button-l @click.native="addTimeFrame" settings="ml-4 hover:text-primary text-sm cursor-pointer">{{ $t('dashboard.add_time') }}</button-l>
        </div>
    </div>
</template>

<script>
import Dropdown from '@/components/ui/Dropdown.vue'
import ButtonL from '@/components/ui/ButtonL.vue'
import { Availability } from 'delivery-boosting-2021-model'
import InputTime from '@/components/dashboard/InputTime.vue'

export default {
    components: { Dropdown, ButtonL, InputTime },
    props: {
        availability: {
            type: Availability
        }
    },
    methods: {
        addTimeFrame () {
            this.availability.addTimeFrame()
        },
        removeTimeFrame (ind) {
            this.availability.removeTimeFrame(ind)
        },
        addAvailableOn (day) {
            this.availability.addAvailableOn(day)
        },
        removeAvailableOn (day) {
            this.availability.removeAvailableOn(day)
        },
        toggleAvailableOn (day) {
            if (this.availability.timeRulesAvailableOn.includes(day)) {
                this.availability.removeAvailableOn(day)
            } else {
                this.availability.addAvailableOn(day)
            }
        }
    }
}
</script>

<style>

</style>
