<template>
    <div class="p-10 bg-gray-600 flex flex-col justify-between min-h-16 relative overflow-hidden rounded-lg">
        <div class="flex justify-between relative z-10">
            <h4 class="text-white cursor-pointer" @click="$router.push({name: 'dashboard-food-list', params: { categoryId: category.uuid }})">{{category.title}}</h4>
            <div class="flex">
                <a @click="deleteAndSave" class="text-white hover:text-primary mr-4 cursor-pointer"><icon-trash class="h-8"/></a>
                <a @click="showEditCategory" class="text-white hover:text-primary cursor-pointer"><icon-cog class="h-8"/></a>
            </div>
        </div>
        <div class="relative z-10">
            <button-l @click.native="addProduct" settings="text-white line cursor-pointer">{{ $t('dashboard.add_meal') }}</button-l>
        </div>
        <img class="left-0 top-0 absolute w-full h-full object-cover rounded-lg z-0 opacity-50" v-if="category.media[0]" :src="category.media[0].url"/>
    <transition name="slide-fade">
    <edit-category :category="category" v-if="isEditCategoryVisible" @close="closeEditCategory" class="cursor-default"/>
    </transition>
    </div>
</template>

<script>
import IconCog from '../icons/IconCog.vue'
import IconTrash from '../icons/IconTrash.vue'
import ButtonL from '../ui/ButtonL.vue'
import { Category } from 'delivery-boosting-2021-model'
import EditCategory from '../../components/modals/EditCategory.vue'

export default {
    name: 'CategoryCard',
    components: { IconTrash, IconCog, ButtonL, EditCategory },
    props: {
        category: {
            type: Category
        }
    },
    data () {
        return {
            isEditCategoryVisible: false

        }
    },
    methods: {
        async deleteAndSave () {
            this.$emit('remove')
        },
        addProduct () {
            const product = this.$store.state.catalog.addProduct()
            this.category.addProduct(product.uuid)
            this.$router.push({ name: 'dashboard-food-detail', params: { productId: product.uuid } })
        },
        showEditCategory () {
            this.isEditCategoryVisible = true
        },
        closeEditCategory () {
            this.isEditCategoryVisible = false
        }
    }
}
</script>

<style>
.slide-fade-enter-active {
  transition: all .5s ease;
}
.slide-fade-leave-active {
  transition: all .5s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(100px);
  opacity: 0;
}
</style>
