<template>
    <dashboard-sidebar>
        <template #content>
                <menu-title-bar>
                    <menu-tab :title="$t('dashboard.categories')">
                        <div @click="closeExtraModal">
                            <draggable
                                v-model="categories"
                                class="grid grid-cols-4 gap-10"
                                ghost-class="opacity-30"
                                handle=".drag"
                            >
                                <category-card class="drag cursor-move" v-for="category in categories" :key="category.uuid" :category="category" @remove="deleteCategory(category)" />
                                <template #header>
                                    <div class="p-10 bg-primary flex flex-col justify-between min-h-16 relative overflow-hidden rounded-lg">
                                        <div @click="$router.push({name: 'dashboard-food-list', params: { categoryId: 'all' }})" class="cursor-pointer flex justify-between relative z-10">
                                            <h4 class="text-white">{{$t('dashboard.all_products')}}</h4>
                                        </div>
                                        <div class="relative z-10">
                                            <button-l @click.native="$router.push({ name: 'dashboard-food-detail', params: { productId: catalog.addProduct().uuid } })" settings="text-white line cursor-pointer">{{ $t('dashboard.add_meal') }}</button-l>
                                        </div>
                                    </div>
                                </template>
                                <template #footer>
                                    <div class="border border-gray-300 border-dashed rounded-md flex h-full min-h-16">
                                        <button-b type="button" @click.native="showModal" class="bg-primary m-auto">{{ $t('dashboard.new_category') }}</button-b>
                                    </div>
                                </template>
                            </draggable>
                            <transition name="slide-fade">
                                <add-category v-if="isModalVisible" @close="closeModal" class="cursor-default"/>
                                <confirm-delete v-if="isDeleteCatgeoryModalVisible" @close="closeIsDeleteCatgeoryModalVisible" :category="categoryToDelete" />
                            </transition>
                        </div>
                    </menu-tab>
                    <menu-tab :title="$t('dashboard.extras')">
                        <card-box>
                            <div class="flex items-center justify-between mb-10">
                                <h4 class="mb-5">{{ $t('dashboard.extras') }}</h4>
                                <button-b type="button" @click.native="showExtraModal" class="bg-primary">{{ $t('dashboard.new_extra') }}</button-b>
                            </div>
                            <transition name="slide-fade">
                            <add-extra v-if="isExtraModalVisible" @close="closeExtraModal"/>
                            </transition>
                            <table-extras :upgrades="upgrades" @change="move"></table-extras>
                        </card-box>
                    </menu-tab>
                    <menu-tab :title="$t('dashboard.additives')">
                        <card-box>
                            <div class="flex items-center justify-between mb-10">
                                <h4 class="mb-5">{{ $t('dashboard.additives') }}</h4>
                                <button-b type="button" @click.native="showModal" class="bg-primary">{{ $t('dashboard.new_additiv') }}</button-b>
                            </div>
                            <transition name="slide-fade">
                            <add-additive v-show="isModalVisible" @close="closeModal"/>
                            </transition>
                            <div class>
                                <draggable
                                v-model="additives"
                                class="grid grid-cols-3 gap-10"
                                ghost-class="opacity-30"
                                >
                                    <additives-card v-for="additive in additives" :key="additive.uuid" :additive="additive" @remove="catalog.removeAdditive(additive.uuid);catalog.commit()" />
                                </draggable>
                            </div>
                        </card-box>
                    </menu-tab>
                </menu-title-bar>
        </template>
    </dashboard-sidebar>
</template>
<script>
import DashboardSidebar from '@/components/layout/DashboardSidebar.vue'
import CategoryCard from '../../components/dashboard/CategoryCard.vue'
import ButtonB from '../../components/ui/ButtonB.vue'
import CardBox from '../../components/dashboard/CardBox.vue'
import MenuTitleBar from '../../components/dashboard/MenuTitleBar.vue'
import MenuTab from '../../components/dashboard/MenuTab.vue'
import TableExtras from '../../components/dashboard/TableExtras.vue'
import AdditivesCard from '../../components/dashboard/AdditivesCard.vue'
import AddCategory from '../../components/modals/AddCategory.vue'
import AddExtra from '../../components/modals/AddExtra.vue'
import AddAdditive from '../../components/modals/AddAdditive.vue'
import ConfirmDelete from '../../components/modals/ConfirmDelete.vue'
import draggable from 'vuedraggable'
import ButtonL from '../../components/ui/ButtonL.vue'
// import UnsplashSearch from '../../components/modals/UnsplashSearch.vue'

export default {
    name: 'Categories',
    data () {
        return {
            categoryToDelete: null,
            isDeleteCatgeoryModalVisible: false,
            isModalVisible: false,
            value: null,
            isExtraModalVisible: false,
            media: {},
            drawerVisible: false
        }
    },
    methods: {
        showModal () {
            this.isModalVisible = true
        },
        closeModal () {
            this.isModalVisible = false
        },
        showExtraModal () {
            this.isExtraModalVisible = true
        },
        closeExtraModal () {
            this.isExtraModalVisible = false
        },
        showIsDeleteCatgeoryModalVisible () {
            this.isDeleteCatgeoryModalVisible = true
        },
        closeIsDeleteCatgeoryModalVisible () {
            this.isDeleteCatgeoryModalVisible = false
        },
        async deleteCategory (category) {
            this.categoryToDelete = category
            this.isDeleteCatgeoryModalVisible = true
        },
        async move (pl) {
            const upgrades = this.$store.state.catalog.upgrades
            const oldIndex = pl.moved.oldIndex - 1
            const newIndex = pl.moved.newIndex - 1
            const temp = upgrades[oldIndex]
            if (oldIndex > newIndex) {
                for (var i = oldIndex; i >= newIndex; i--) {
                    upgrades[i] = upgrades[i - 1]
                }
            } else if (newIndex > oldIndex) {
                for (var o = oldIndex; o < newIndex; o++) {
                    upgrades[o] = upgrades[o + 1]
                }
            }
            upgrades[newIndex] = temp
            this.$store.state.catalog.upgrades = upgrades
            await this.$store.state.catalog.commit()
        }

    },
    components: {
        draggable,
        DashboardSidebar,
        CategoryCard,
        ButtonB,
        CardBox,
        MenuTitleBar,
        MenuTab,
        TableExtras,
        AdditivesCard,
        AddCategory,
        AddExtra,
        AddAdditive,
        ConfirmDelete,
        ButtonL
    },
    computed: {
        restaurant () {
            return this.$store.state.restaurant
        },
        catalog () {
            return this.$store.state.catalog
        },
        categories: {
            get () {
                return this.catalog.categories
            },
            set (val) {
                console.log(val)
                this.catalog.categories = val.map(c => c.toPlainObject())
                this.catalog.commit()
            }
        },
        additives: {
            get () {
                return this.catalog.additives
            },
            set (val) {
                console.log(val)
                this.catalog.additives = val.map(c => c.toPlainObject())
                this.catalog.commit()
            }
        },
        upgrades: {
            get () {
                return this.catalog.upgrades
            },
            set (val) {
                console.log(val)
                this.catalog.upgrades = val.map(c => c.toPlainObject())
                this.catalog.commit()
            }
        }
    }
}

</script>

<style>
.slide-fade-enter-active {
  transition: all .5s ease;
}
.slide-fade-leave-active {
  transition: all .5s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(100px);
  opacity: 0;
}
</style>
