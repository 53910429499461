<template lang="html">
    <div>
        <div class="flex items-center justify-between pb-10">
            <div class="flex-1 min-w-0">
                <h1 class="text-gray-900">{{ $t('dashboard.menu') }}</h1>
            </div>
            <div class="tabs-menu-title-bar">
                <nav class="-mb-px flex space-x-14" aria-label="Tabs">
                    <a class="font-bold cursor-pointer text-gray-600" v-for='(tab, index) in tabs' :key='tab.title' @click='selectTab(index)' :class='{"tab__selected": (index == selectedIndex)}'>
                        {{ tab.title }}
                    </a>
                </nav>
            </div>
        </div>
        <slot/>
    </div>
</template>

<script>
export default {
    props: {
        mode: {
            type: String,
            default: 'light'
        }
    },
    data () {
        return {
            selectedIndex: 0,
            tabs: []
        }
    },
    created () {
        this.tabs = this.$children
    },
    mounted () {
        this.selectTab(0)
    },
    methods: {
        selectTab (i) {
            this.selectedIndex = i
            this.tabs.forEach((tab, index) => {
                tab.isActive = (index === i)
            })
        }
    }
}
</script>
